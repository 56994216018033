.oleez-about-features {
    padding-top: 60px;
    padding-bottom: 60px;
    
    .feature-card {
        .feature-card-title {
            font-size: 20px;
            font-weight: $font-weight-medium;
            line-height: 1.2;
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &::after {
                content: "";
                display: inline-block;
                width: 45px;
                height: 3px;
                background-color: $black;
                margin-left: 10px;
            }
        }

        .feature-card-content {
            font-size: 15px;
            font-weight: $font-weight-light;
            line-height: 1.67;
        }
    }
}

.oleez-what-we-do {
    padding-top: 97px;
    padding-bottom: 109px;

    .section-title {
        font-size: 45px;
        font-weight: $font-weight-bold;
        line-height: 0.62;
        margin-bottom: 22px;
    }

    .section-subtitle {
        font-size: 24px;
        font-weight: $font-weight-bold;
        margin-bottom: 39px;
    }

    .what-we-do-list-title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        line-height: 1.1;
        margin-bottom: 9px;
    }

    .what-we-do-list {
        list-style: none;
        margin: 0;
        padding-left: 0;

        li {
            font-size: 15px;
            font-weight: $font-weight-light;
            line-height: 1.67;
        }
    }
}

.oleez-about-work-with-us {
    padding-bottom: 119px;

    .section-title {
        font-size: 30px;
        font-weight: $font-weight-medium;
        line-height: 1.33;
        margin-bottom: 10px;
    }

    .section-subtitle {
        font-size: 20px;
        font-weight: $font-weight-light;
        line-height: 1.75;
        margin-bottom: 27px;

        a {
            color: inherit;
            display: inline-block;
            border-bottom: 1px solid $body-color;
            line-height: 1;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $black;
                text-decoration: none;
                color: $white;
                padding: 5px;
                margin: -5px;
            }
        }
    }

    .work-with-us-btn {
        background-color: $black;
        border-radius: 0;
        border: 0;
        padding: 12px 24px;
        font-size: 18px;
        font-weight: $font-weight-bold;
        color: $white;
    }
}