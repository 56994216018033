.portfolio-grid-page {
    padding-bottom: 110px;

    .portfolio-card {
        margin-bottom: 56px;
        cursor: pointer;

        &:hover {
            .project-thumbnail {
                transform: scale(1.1);
            } 
        }
        .project-thumbnail-wrapper {
            width: 100%;
            height: 195px;
            margin-bottom: 25px;
            overflow: hidden;

            .project-thumbnail {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.3s ease-in-out;
            }
        }
        .project-title {
            font-size: 18px;
            font-weight: $font-weight-bold;
            line-height: 1;
            margin-bottom: 7px;
        }
        .project-category {
            font-size: 14px;
            line-height: 1;
            margin-bottom: 0;
        }
    }
}

.portfolio-masonry-page {
    .grid-sizer {
        width: calc(25% - 20px);
    }

    .grid-item {
        margin-bottom: 25px;
        width: calc(25% - 20px);
        
        &::before {
            content: "";
            display: block;
            padding: 50% 0;
            float: left;
        }


        &.col-span-2 {
            width: calc(50% - 20px);

            &::before {
                padding: calc(25% - 5.5px) 0;
            }
        }

        &.row-span-2 {
            &::before {
                padding: calc(100% + 5.5px) 0;
            }

            &.col-span-2 {
                &::before {
                    padding: calc(50% - 3.75px) 0;
                }
            }
        }

        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}
.portfolio-list {
    .project {
        margin-bottom: 50px;

        .project-content {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .project-title {
                font-size: 35px;
                font-weight: $font-weight-bold;
                line-height: 1.29;
                margin-bottom: 20px;
            }

            .project-link {
                font-size: 22px;
                color: inherit;
                display: flex;
                align-items: center;
                &::after {
                    content: "";
                    display: inline-block;
                    width: 70px;
                    border-bottom: 1px solid #979797;
                    margin-left: 15px;
                }
            }
        }
        .project-thumbnail-wrapper {
            height: 330px;
            overflow: hidden;
            
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.2s ease;
            }
        }

        &:hover {
            .project-thumbnail-wrapper {
                img {
                    transform: scale(1.075);
                }
            }
        }
    }
}