// Color system
$black: #000000;
$white: #ffffff;

$theme-colors: (
    "primary":    #f7b500
);

$enable-caret: false;

// Body
//
// Settings for the `<body>` element.

$body-bg:               $white;
$body-color:            #100e0e;

// Typography

$font-family-base:      'Heebo', sans-serif;

$font-weight-light:     300;
$font-weight-regular:   400;
$font-weight-medium:    500;
$font-weight-bold:      700;

$font-weight-base:      $font-weight-regular;

$headings-font-weight:  $font-weight-medium;

// Navbar 
$navbar-padding-y:                  1.25rem;
$navbar-padding-x:                  2.125rem;
$navbar-brand-height:               2.687rem;
$navbar-brand-padding-y:            7px;
$navbar-nav-link-padding-x:         1.562rem;
$navbar-light-color:                $black;
$navbar-light-hover-color:          $black;
$navbar-light-active-color:         $black;

