.oleez-footer {
    background-color: #0e0e0f;
    color: $white;

    .footer-content {
        padding-top: 90px;
        padding-bottom: 45px;

        .footer-logo {
            height: 30px;
            margin-bottom: 19px;
        }

        .footer-intro-text {
            font-size: 18px;
            line-height: 1.5;
            margin-bottom: 26px;
            max-width: 288px;
        }

        .footer-social-links {
            a {
                font-size: 14px;
                font-weight: $font-weight-bold;
                line-height: 1;
                color: inherit;
                margin-right: 10px;
            }
        }

        .footer-widget-text {
            margin-bottom: 40px;

            .widget-title {
                font-size: 18px;
                font-weight: $font-weight-medium;
                line-height: 1.5;
                margin-bottom: 6px;
                color: theme-color();
            }

            .widget-content {
                font-size: 14px;
                line-height: 1.29;
            }
        }
    }

    .footer-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 44px;
        padding-bottom: 44px;

        @media (max-width: 767px) {
            display: block;
        }

        p {
            font-size: 14px;
            line-height: 1.29;
        }
    }
}