.oleez-page-title {
    font-size: 40px;
    font-weight: $font-weight-bold;
    margin-bottom: 27px;
    padding-top: 29px;
}

.oleez-page-header-content {
    font-size: 24px;
    font-weight: $font-weight-medium;
    margin-bottom: 38px;
}

.oleez-pagination {
    a {
        display: inline-flex;
        width: 24px;
        height: 24px;
        font-size: 20px;
        line-height: 1;
        margin-right: 16px;
        color: inherit;
        align-items: center;
        justify-content: center;
        position: relative;

        &::after {
            content: "";
            display: inline-block;
            width: 0;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background-color: theme-color();
            transition: all 0.2s ease;
        }

        &.active, &:hover {
            text-decoration: none;

            &::after {
                width: 100%;
            }
        }

        // &.active {
        //     &::after {
        //         content: "";
        //         display: inline-block;
        //         width: 100%;
        //         height: 2px;
        //         position: absolute;
        //         bottom: 0;
        //         left: 0;
        //         background-color: theme-color();
        //     }
        // }

        &.next, &.previous {
            width: 35px;
            height: 35px;
            border: 2px solid $black;
            
            &::after {
                content: none;
            }
        }
    }
}