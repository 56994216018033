.shop-page {
    padding-bottom: 150px;

    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 31px 0;

        .result-count {
            font-size: 20px;
        }
    }

    .product-card {
        text-align: center;
        margin-bottom: 75px;

        &:hover {
            .product-thumbnail {
                transform: scale(1.1);
            }
        }

        .product-thumbnail-wrapper {
            padding: 50% 0;
            position: relative;
            overflow: hidden;
            margin-bottom: 19px;

            .product-thumbnail {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: transform 0.5s ease-in-out;
            }
        }

        .product-title {
            font-size: 20px;
            font-weight: $font-weight-bold;
            line-height: 1.4;
        }

        .product-price {
            font-size: 16px;
            font-weight: $font-weight-bold;
            line-height: 1.75;
            margin-bottom: 14px;

            del {
                font-weight: $font-weight-normal;
                color: #bebebe;
                padding-left: 7px;
            }
        }

        .btn-add-to-cart {
            border-radius: 0;
            border: 1px solid $black;
            padding: 12px 24px;
            font-size: 12px;
            font-weight: $font-weight-bold;
            color: $black;
            transition: all 0.3s ease-in-out;

            &:hover {
                background-color: $black;
                color: $white;
            }
        }
    }
}