.blog-grid-page {
    .blog-post-card {
        margin-bottom: 90px;
        
        .blog-post-thumbnail-wrapper {
            width: 100%;
            height: 200px;
            overflow: hidden;
            margin-bottom: 30px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .blog-post-date {
            font-size: 14px;
            line-height: 1;
            margin-bottom: 6px;
        }

        .blog-post-title {
            font-size: 20px;
            font-weight: $font-weight-medium;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }
}

.blog-post-single {
    .post-title {
        font-size: 40px;
        font-weight: $font-weight-bold;
        margin-bottom: 30px;
        margin-top: 30px;
    }

    .blog-post-wrapper {

        .post-header {
            .post-featured-image {
                width: 100%;
                margin-bottom: 14px;
            }
    
            .post-date {
                font-size: 14px;
                line-height: 2.86;
            }
        }
        .post-content {
            h4 {
                margin-bottom: 16px;
            }

            p {
                font-size: 14px;
                font-weight: $font-weight-light;
                line-height: 1.71;
                margin-bottom: 16px;
            }

            blockquote {
                background-color: #e6ecf2;
                padding: 27px 40px 27px 84px;
                margin-bottom: 24px;
                position: relative;

                &::before {
                    content: "\201c";
                    font-family: monospace;
                    font-size: 75px;
                    color: theme-color();
                    position: absolute;
                    left: 30px;
                    top: 15px;
                    line-height: 1;
                }

                p {
                    font-size: 16px;
                    font-weight: $font-weight-medium;
                    line-height: 1.63;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .post-tags {
            padding-top: 34px;
            padding-bottom: 34px;
            border-bottom: 2px solid #e6ecf2;
            margin-bottom: 30px;
        }

        .post-navigation {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 49px;

            .prev-post {
                &:focus {
                    box-shadow: none;
                }

                &::before {
                    content: "\2190";
                    margin-right: 6px;
                    transition: transform 0.4s ease;
                    display: inline-block;
                }

                &:hover::before {
                    transform: translateX(-5px);
                }
            }

            .next-post {
                &:focus {
                    box-shadow: none;
                }

                &::after {
                    content: "\2192";
                    margin-left: 6px;
                    transition: transform 0.4s ease;
                    display: inline-block;
                }

                &:hover::after {
                    transform: translateX(5px);
                }
            }
        }

        .comment-section {
            margin-bottom: 108px;

            .section-title {
                margin-bottom: 36px;
            }
        }

        .oleez-comment-form {
            .oleez-input, .oleez-textarea {
                width: 100%;
            }
        
            label {
                font-size: 14px;
                font-weight: $font-weight-bold;
                margin-bottom: 10px;
            }
        
            .form-group {
                position: relative;
                margin-bottom: 30px;
            }
        
            .oleez-input {
                border: 0;
                border-bottom: 2px solid $black;
                padding: 9px 0;
                font-size: 14px;
                font-weight: $font-weight-bold;
        
                &:focus {
                    outline: none;
                }
        
                + label {
                    position: absolute;
                    margin-bottom: 0;
                    left: 15px;
                    bottom: 9px;
                    transition: all 0.3s ease;
                }
        
                &:focus, &:valid {
                    + label {
                        bottom: 34px;
                        font-size: 12px;
                        font-weight: $font-weight-normal;
                    }
                }
            }
        
            .oleez-textarea {
                border: 2px solid #010101;
                padding: 9px;

                &:focus {
                    outline: none;
                }
            }
        
            .btn-submit {
                padding: 17px 50px;
                background-color: #131212;
                border-radius: 0;
                color: $white;
                font-size: 18px;
                font-weight: $font-weight-bold;
        
                &:hover {
                    background-color: transparent;
                    border: 1px solid #131212;
                    color: #131212;
                }
            }
        }
    }
}

.post-tag {
    display: inline-block;
    padding: 7px 15px;
    border: 1px solid #e6ecf2;
    font-size: 10px;
    font-weight: $font-weight-medium;
    line-height: 1.4;
    color: inherit;
    margin-right: 12px;
    margin-bottom: 9px;
}

.sidebar-widget{
    border-bottom: 1px solid #e6ecf2;
    margin-bottom: 31px;

    .widget-title {
        font-size: 20px;
        font-weight: $font-weight-medium;
        line-height: 1;
        margin-bottom: 20px;
    }
    .widget-content {
        padding-bottom: 24px;
    }

    .social-links {
        a {
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 1;
            color: inherit;
            margin-right: 10px;
            display: inline-block;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .gallery-grid-item {
            position: relative;
            display: block;
            width: calc((100% - 27px) / 4);
            margin-bottom: 9px;
            height: auto;

            &::before {
                content: "";
                padding-top: 100%;
                float: left;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .category-list {
        list-style: none;
        padding-left: 0;
        
        li {
            font-size: 16px;
            line-height: 2.5;

            a {
                color: inherit;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.blog-standard {
    .blog-post {
        margin-bottom: 45px;
        .post-thumbnail {
            width: 100%;
            max-height: 300px;
            object-fit: cover;
            margin-bottom: 14px;
        }
        .post-date {
            font-size: 14px;
            line-height: 2.86;
            margin-bottom: 4px;
        }
        .post-title {
            font-size: 25px;
            font-weight: $font-weight-medium;
            line-height: 1.4;
            margin-bottom: 9px;
        }
        .post-excerpt {
            font-size: 14px;
            font-weight: $font-weight-light;
            line-height: 1.71;
            margin-bottom: 15px;
        }
        .post-permalink {
            font-size: 14px;
            font-weight: $font-weight-medium;
            line-height: 0.88px;
            color: inherit;

            &::after {
                content: "\2192";
                margin-left: 6px;
                transition: transform 0.4s ease;
                display: inline-block;
            }

            &:hover{
                text-decoration: none;

                &::after {
                    transform: translateX(5px);
                }
            }

        }
    }
    .oleez-pagination {
        margin-bottom: 54px;
    }
}